<template>
  <v-card class="shadow-0  bordered mt-2 rounded-3 mt-8"  v-if="suggestedProducts.length && getCartItems.length">
    <v-card-actions class="bg-neon">
      <div class="d-flex gap-x-2 pt-2 pb-2">
        <IdeaIcon class="ml-1"/>
        <p class="text-white m-0 text-lg">Suggestions to buy together</p>
      </div>
    </v-card-actions>
    <v-card-text>
      <div
          v-for="(product, index) in suggestedProducts"
          :key="index"
          class="d-flex justify-space-between mb-4 align-center "
          :class="{ 'border-bottom pb-2': index !== suggestedProducts.length - 1 }"
      >
        <div class="d-flex gap-x-4 align-center">
          <v-img
              :aspect-ratio="1"
              :src="product.image ? s3BucketURL + product.image : defaultImges.default"
              height="80"
              width="80"
              class="rounded-2"
          />
          <div>
            <p class="text-gray2 m-0 text-xs font-medium">{{product.product_type ==='POS' ? 'Retail' : product.product_type}} </p>
            <p class="black--text font-semibold  text-base">{{ getParentName(product) }} <span class="d-block font-12 text-neon">{{product.name}} - {{ product.total_price | toCurrency }}</span></p>

          </div>
        </div>
        <div class="d-flex align-end">
          <v-btn
              style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
              class="ma-2 pa-2  text_capitalize "
              outlined
              @click="buySuggestedProduct(product)"
              dark>Add</v-btn>
        </div>
      </div>
    </v-card-text>
    <ShopCustomerPopup
        v-if="showCustomerPopup"
        :promotions="promotions"
        :bookingType="bookingType"
        :show-customer-popup="showCustomerPopup"
        @close="closeCustomerPopup"
        @setCustomers="setCustomers"
        :bookingData="bookingForm"
        :max-customers="maxCustomers"
    />
    <MembershipPackagesModal
        v-if="membership"
        :membership="membership"
        :packages-modal="membershipPackageModal"
        @close="closeCustomerPopup"
    />
    <ShopAcademyPackages
        v-if="academy"
        :academy="academy"
        :is_filter_product="academy.is_filter_product"
        :product_id="academy.product_id"
        :academyPackageModal="academyPackagesModal"
        @close="closeCustomerPopup"
    />
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="closeCustomerPopup" />
  </v-card>

</template>

<script >
import IdeaIcon from '@/assets/images/shop/idea.svg';
import images from "@/utils/images";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import moment from "moment/moment";
import MembershipPackagesModal from "@/components/Shop/Memberships/MembershipPackagesModal.vue";
import ShopAcademyPackages from "@/components/Shop/Academy/ShopAcademyPackages.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";
export default {
  components:{PackageCardPopup, ShopAcademyPackages, MembershipPackagesModal, ShopCustomerPopup, IdeaIcon},
  props:{refreshSuggestion: { type: Boolean, default: false }},
  watch: {
    refreshSuggestion(val) {
        if(val){
          this.suggestedProducts = [];
          this.getSuggestedProducts();
        }
    }
  },
  data(){
    return {
      offers: [],
      showOfferPopup: false,
      suggestedProducts:[],
      defaultImges: images,
      bookingForm: {
        venue_id: null,
        start_date: moment().format("YYYY-MM-DD"),
        products: [],
        productCategoryId: null,
        attendance_count: 1,
        opt_marketing: false,
        price: 0,
        discount: null,
        total_price: 0,
        venue_service_id: null,
        event_id: null,
        event_name: null,
        trainer_id: null,
        package_id: null,
        package_type: null,
        product_id: null,
        trainer_name: null,

      },
      showCustomerPopup: false,
      selectedProduct: null,
      bookingType: null,
      maxCustomers: 1,
      eventTicket: null,
      membership:null,
      membershipPackageModal: false,
      academy: null,
      academyPackagesModal: false,
    }
  },
  mounted() {
    this.getSuggestedProducts();
  },
  computed: {
    getCurrentVenueId(){
      return this.$store.getters.svid;
    },
    getCartItems(){
      return this.$store.getters.getCartItems;
    },
    retailVenueServiceId() {
      const filteredService = this.$store.getters.getShopVenueServices.data.find(item => item.name.toLowerCase() === "pos");
      if (filteredService) {
        return filteredService.id;
      } else {
        return null;
      }
    },
    promotions() {
      return this.$store.getters.getShopPromotions.data;
    },
  },
  methods:{
    resetData(){
        this.offers = [];
        this.showOfferPopup= false;
        this.bookingForm = {
          start_date: moment().format("YYYY-MM-DD"),
          products: [],
          productCategoryId: null,
          attendance_count: 1,
          opt_marketing: false,
          price: 0,
          discount: null,
          total_price: 0,
          e_venue_service_id: null,
          event_id: null,
          event_name: null,
          trainer_id: null,
          package_id: null,
          package_type: null,
          product_id: null,
          trainer_name: null,
        };
        this.showCustomerPopup = false;
        this.selectedProduct = null;
        this.bookingType = null;
        this.maxCustomers = 1;
        this.eventTicket = null;
        this.membershipPackageModal = false;
        this.membership = null;
        this.academy = null;
        this.academyPackagesModal = false;
    },
    setCustomers(data){
      if(this.bookingType === "retail"){
          this.setRetailCustomers(data);
      }else if(this.bookingType === "event"){
        this.setEventCustomers(data);
      }else if(this.bookingType === "trainer"){
        this.setTrainerCustomers(data);
      }
    },
    getParentName(product){
      switch(product.product_type_id) {
        case 2:
          return product.membership_name;
        case 3:
          return product.event_name;
        case 4:
          return product.workshop_name;
        case 5:
          return product.trainer_name;
        case 6:
          return product.facility_name;
        default:
          return 'Product';
      }
    },
    getSuggestedProducts(){
      const cartItemIds = this.$store.getters.getCartItems
          .map(p => p.products.map(pr => pr.product_id))
          .flat();

      if(cartItemIds && cartItemIds.length){
        this.$http.post(`venues/offers/suggestions/get/suggested-products`, {
          productIds:cartItemIds && cartItemIds.length ?cartItemIds :[]
        })
            .then((response) => {
              if (response.status === 200 && response.data.status === true) {
                this.suggestedProducts = response.data.data;
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            }).finally(() => {
          this.hideLoader();
        });
      }
    },
    buySuggestedProduct(product){
      if(product.product_type_id === 2){
        this.bookingType = 'membership';
        this.membershipAddToCart(product);
      }else if(product.product_type_id === 3){
        this.bookingType = 'event';
        this.eventAddToCart(product);
      }else if(product.product_type_id === 4){
        this.bookingType = 'academy';
        this.academyAddToCart(product);
      }else if(product.product_type_id === 5){
        this.bookingType = 'trainer';
        this.trainerAddToCart(product);
      }else if(product.product_type_id === 6){
        this.bookingType = null;
        this.$router.push(`/shop/facility?vid=${product.venue_id}&vsid=${product.f_venue_service_id}&fid=${product.facility_id}`);
      }else if(product.product_type_id === 9){
        this.bookingType = 'retail'
        this.retailAddToCart(product);
      }
    },
    addOrUpdateRetailCartItem(foundProduct) {
      let venueId = foundProduct.venue_id;
      let category= foundProduct.category;
      let productImage = foundProduct.image;
      let cartData = this.getCartItems.find( (ci) => ci.type === "retail" && ci.venueId === venueId);
      let existingProduct = null;
      if(cartData){
        existingProduct = cartData.products.find(item => item.product_id === foundProduct.product_id);
      }
      if(foundProduct.enable_retail_inventory){
        if(existingProduct && foundProduct.stock_level < ( existingProduct.quantity + 1 ) ){
          this.showError("Only "+foundProduct.stock_level+" quantity available in stock");
          return;
        }else{
          if(foundProduct.stock_level < 1){
            if(foundProduct.stock_level === 0){
              this.showError("Product Out Of Stock");
            }else{
              this.showError("Only "+foundProduct.stock_level+" quantity available in stock");
            }
            return;
          }
        }
      }
      if (existingProduct) {
        existingProduct.quantity = existingProduct.quantity + 1;
        this.checkProductOffers();
      } else {
        // If the product does not exist in the cart, add it to the cart items
        let product  = {
          id: foundProduct.product_id,
          product_id: foundProduct.product_id,
          product_type_id: 9,
          name:foundProduct.name,
          price:foundProduct.price,
          total_price: (foundProduct.price) + (foundProduct.tax_amount),
          quantity: 1,
          tax:foundProduct.tax_amount,
          category:category,
          image:productImage,
          enable_retail_inventory:foundProduct.enable_retail_inventory,
          stock_level:foundProduct.stock_level
        };

        this.$store.dispatch("addRetailToCart",{product: product,venueId:venueId}).then( () => {
          this.showSuccess("Successfully Added");
          this.checkProductOffers();
        });
      }
    },

    eventAddToCart(product) {
      this.showLoader('Loading Event Ticket');
      this.bookingForm.venue_id = product.venue_id?product.venue_id:this.getCurrentVenueId;
      this.$store.dispatch("loadShopPromotions", {
        date: this.bookingForm.start_date,
        product_type: "Event",
        venueId: product.venue_id,
      }).then( () => {
        this.$http
            .get(`venues/shop/events/event-ticket/${product.product_id}`)
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.selectedProduct = response.data.data;
                this.selectedProduct.venue_service_id = product.e_venue_service_id;
                this.selectedProduct.event_id = product.event_id;
                this.selectedProduct.event_name = product.event_name;
                this.selectedProduct.event_start_date = product.event_start_date;
                this.selectedProduct.quantity = 1;
                this.maxCustomers = product.participant_count;
                this.bookingForm.products = [];
                this.bookingForm.products.push({...this.selectedProduct});
                if (this.bookingForm.products.length) {
                  this.showCustomerPopup = true;
                } else {
                  this.showError('Please add a ticket');
                }
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            })
            .finally(() => {
              this.hideLoader();
            });
      });
    },
    academyAddToCart(product) {
      this.academy = {
        id: product.workshop_id,
        name: product.workshop_name,
        venue_id: product.venue_id,
        product_id: product.product_id,
        is_filter_product: true,
      };
      this.academyPackagesModal = true;
    },
    trainerAddToCart(product) {
      this.showLoader('Loading Data');
      this.$http
        .get(`venues/shop/trainers/packages/${product.trainer_id}?venueId=${product.venue_id}&product_id=${product.product_id}`)
        .then((response) => {
          if (response.status === 200 && response.data.status === true) {
            let trainerPackages = response.data.data.filter(packageItem =>
                packageItem.product_id === product.product_id &&
                packageItem.trainer_id === product.trainer_id
            );
            // Validate that the filtered result is not empty
            if (trainerPackages.length > 0) {
              trainerPackages = trainerPackages[0];
            } else {
              trainerPackages = null; // Handle the case when no matching package is found
              this.showError('Trainer package not available');
              return false;
            }
            this.bookingForm.venue_id = product.venue_id;
            this.bookingForm.trainer_name = product.trainer_name;
            this.bookingForm.trainer_id = trainerPackages.trainer_id;
            this.bookingForm.venue_service_id = trainerPackages.venue_service_id;
            this.bookingForm.package_id = trainerPackages.package_id;
            this.bookingForm.product_id = trainerPackages.product_id;
            this.bookingForm.package_type = trainerPackages.package_type;
            trainerPackages.quantity = 1;
            trainerPackages.name = product.name;
            this.bookingForm.products.push(trainerPackages);
            this.$store.dispatch("loadShopPromotions", {
              product_type: "Trainer",
              trainer_id: trainerPackages.trainer_id,
              venue_service_id: trainerPackages.venue_service_id,
              product_id: trainerPackages.product_id,
              venueId: product.venue_id,
            }).finally( () => {
              this.showCustomerPopup = true;
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    membershipAddToCart(product){
      this.membership = null;
      this.membershipPackageModal = false;
      this.showLoader('Loading Data');
      this.$http
          .get(`venues/shop/memberships/details/${product.membership_id}?venueId=${product.venue_id}&product_id=${product.product_id}`)
          .then((response) => {
            if (response.status === 200 && response.data.status === true) {
              let membershipData = response.data.data;
              this.filterMembershipData(membershipData,product.product_id);
              let filterMembershipData = JSON.parse(JSON.stringify(membershipData));

              let newObject = {};
              newObject.venue_id = product.venue_id;
              newObject.id = filterMembershipData.id;
              newObject.name = filterMembershipData.name;
              newObject.description = filterMembershipData.description;
              let newPackages = [];
              filterMembershipData.durations.forEach((item) => {
                item.packages.forEach((pkg) => {
                  let innerPackage = {};
                  [innerPackage.name, innerPackage.price] = this.getMembershipPricingDetails(pkg);
                  innerPackage.duration = this.installmentFormatDuration(item);
                  innerPackage.id = pkg.id;

                  let newPricing = [];
                  pkg.pricing.forEach(plan => {
                    let innerPlan = {};
                    innerPlan.name = plan.name;
                    innerPlan.id = plan.id;
                    innerPlan.product_id = plan.product_id;
                    innerPlan.product_type_id = plan.product_type_id;
                    innerPlan.price = plan.price;
                    innerPlan.total_amount = plan.total_amount;
                    innerPlan.tax = plan.tax_amount;
                    innerPlan.total_price = plan.total_price;
                    innerPlan.divisor = plan.installment.divisor;
                    innerPlan.member_limit = pkg.members_limit
                    newPricing.push(innerPlan);
                  })
                  innerPackage.plans = newPricing;
                  newPackages.push(innerPackage);
                })
              })
              newObject.packages = newPackages;
              this.membership = newObject;
              this.membershipPackageModal = true;
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.membership = null;
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    filterMembershipData(membershipData, product_id) {
      // Iterate over each duration and filter packages based on product_id
      membershipData.durations.forEach((duration) => {
        // Filter packages by product_id and update the packages array in place
        duration.packages = duration.packages.filter(packageItem => {
          // Filter the pricing array within the package by product_id
          packageItem.pricing = packageItem.pricing.filter(pricing => pricing.product_id === product_id);

          // Return the package only if it has at least one matching pricing item
          if(packageItem.product_id){
            return packageItem.product_id === product_id && packageItem.pricing.length > 0;
          }else{
            return packageItem.pricing.length > 0;
          }

        });
      });
      return membershipData; // Return the modified original data
    },
    retailAddToCart(product){
      // check customer info is added in cart or not
      let cartItems = this.$store.getters.getCartItems;
      let isPopup = true;
      if(cartItems.length){
        let retail = cartItems.find( (item) => item.type === 'retail' && item.venueId === product.venue_id);
        if(typeof retail != "undefined" && retail.customers && retail.customers.length){
          isPopup = false;
        }
      }
      if(isPopup){
        this.selectedProduct = {...product};
        this.bookingForm.venue_id = product.venue_id?product.venue_id:this.getCurrentVenueId;
        this.showCustomerPopup = true;
      } else {
        this.selectedProduct = null;
        this.addOrUpdateRetailCartItem(product)
      }

    },

    setRetailCustomers(customers) {
      let venueId = this.selectedProduct.venue_id;
      customers.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      this.$store.dispatch("addRetailCustomer",{customers:customers,venueId:venueId,venue_service_id: this.retailVenueServiceId}).then( () => {
        let d = {...this.selectedProduct};
        this.addOrUpdateRetailCartItem(d);
        this.resetData();
      });
    },
    setEventCustomers(data) {
      let venueId = this.selectedProduct.venue_id;
      let cartData = {products: []};
      cartData.venueId = venueId;
      cartData.type = "event";
      cartData.event_id = this.selectedProduct.event_id;
      cartData.event_name = this.selectedProduct.event_name;
      cartData.venue_service_id = this.selectedProduct.venue_service_id;
      cartData.start_date = this.selectedProduct.event_start_date;
      cartData.date = this.selectedProduct.event_start_date;
      cartData.discount = this.bookingForm.discount ? this.bookingForm.discount : null;
      if (this.bookingForm.promotion_code) {
        cartData.promotion_code = this.bookingForm.promotion_code;
      }

      data.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      cartData.customers = data;
      let tickets = JSON.parse(JSON.stringify(this.bookingForm.products));
      tickets = tickets.map((ele) => {
        ele.quantity = 1;
        if (ele.quantity > 0) {
          if (ele.discount) {
            if (ele.product_price && ele.price >= ele.product_price) {
              ele.price = (ele.price / ele.quantity);
              ele.tax = (ele.tax / ele.quantity);
            }
            ele.total_price = ele.price + (ele.tax || ele.tax === 0 ? ele.tax : ele.tax_amount);
          } else {
            if (ele.product_price && ele.price > ele.product_price) {
              ele.price = (ele.price / ele.quantity);
              if ( (ele.tax || ele.tax === 0) && (ele.tax_amount || ele.tax_amount === 0) && ele.tax > ele.tax_amount) {
                ele.tax = (ele.tax / ele.quantity);
              }
              ele.total_price = ele.price + (ele.tax || ele.tax === 0 ? ele.tax : ele.tax_amount);
              // ele.total_price = ele.price + (ele.tax?ele.tax:ele.tax_amount);
            } else {
              ele.total_price = ele.price + (ele.tax || ele.tax === 0 ? ele.tax : ele.tax_amount);
            }
          }
        } else {
          ele.total_price = 0;
        }
        return ele;
      });
      let pushObject = tickets.filter(ele => ele.quantity > 0)
      cartData.products = [...cartData.products, ...pushObject];
      this.$store.dispatch("addToCart", JSON.parse(JSON.stringify(cartData))).then( () => {
        this.resetData();
        this.checkProductOffers();
        //this.$emit('checkProductOffers');
      });
    },
    setTrainerCustomers(customers){
      let cartData = {products: []};
      cartData.venueId = this.bookingForm.venue_id;
      cartData.type = "trainer";
      cartData.trainer_id = this.bookingForm.trainer_id;
      cartData.venue_service_id = this.bookingForm.venue_service_id;
      cartData.package_id = this.bookingForm.package_id;
      cartData.product_id = this.bookingForm.product_id;
      cartData.package_type = this.bookingForm.package_type;
      cartData.trainer_name = this.bookingForm.trainer_name;
      cartData.discount = this.bookingForm.discount?this.bookingForm.discount:null;
      if(this.bookingForm.promotion_code){
        cartData.promotion_code = this.bookingForm.promotion_code;
      }
      this.bookingForm.quantity = 1;

      this.bookingForm.name = this.bookingForm.products[0].product_name;
      customers.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      cartData.customers = customers;

      let pushObject = this.bookingForm.products.filter(ele => ele.quantity > 0)

      cartData.products = [...cartData.products, ...pushObject];
      this.$store.dispatch("addToCart", {...cartData}).then( () => {
        this.resetData();
        this.checkProductOffers();
      });
    },
    getMembershipPricingDetails(pkg) {
      let price = pkg.pricing.length ? pkg.pricing[0].total_price : 0;
      let name = pkg.pricing.length ? pkg.pricing[0].name : 'N/A';
      return [name, price];
    },
    installmentFormatDuration(duration) {
      let parts = [];

      if (duration.year) {
        parts.push(duration.year + (duration.year === 1 ? ' Year' : ' Years'));
      }

      if (duration.month) {
        parts.push(duration.month + (duration.month === 1 ? ' Month' : ' Months'));
      }

      if (duration.day) {
        parts.push(duration.day + (duration.day === 1 ? ' Day' : ' Days'));
      }

      return parts.join(', ');
    },
    closeCustomerPopup() {
      this.selectedProduct = null;
      this.showCustomerPopup = false;
      this.membershipPackageModal = false;
      this.academyPackagesModal = false;
      this.showOfferPopup = false;
    },
    checkProductOffers(){
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
        if (response && response.status == 200 && response.data.status == true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.offers = [];
          }
        }
      });
    },

  },

}
</script>
<template>
  <v-container class="w-full" fluid>
    <BackButton :handler="goToShop"/>
    <h3 class="text-center text-2xl mb-7 w-full">
      Shop Order
    </h3>
    <div class="max-w-1280">
      <v-row>
        <v-col md="6" sm="12">
          <CartItems />
          <CartSuggestedProducts :refreshSuggestion="refreshSuggestion"/>
        </v-col>
        <v-col lg="5" md="6" sm="12">
          <CartPayment @bookingConfirm="bookingConfirm"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12">
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import CartItems from "@/components/Shop/Cart/CartItems.vue";
import CartPayment from "@/components/Shop/Cart/CartPayment.vue";
import CartSuggestedProducts from "@/components/Shop/Cart/CartSuggestedProducts.vue";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: {BackButton, CartPayment, CartItems , CartSuggestedProducts},
  data() {
    return {
      refreshSuggestion: false,
    }
  },
  methods: {
    goToShop() {
      let path;
      if (this.checkReadPermission(this.$modules.facility.schedule.slug)) {
        path = 'ShopFacility'
      } else if (this.checkReadPermission(this.$modules.events.schedule.slug)) {
        path = 'ShopEvents'
      } else if (this.checkReadPermission(this.$modules.memberships.dashboard.slug)) {
        path = 'ShopMemberships'
      } else if (this.checkReadPermission(this.$modules.trainers.dashboard.slug)) {
        path = 'ShopTrainers'
      } else if (this.checkReadPermission(this.$modules.workshops.schedule.slug)) {
        path = 'ShopWorkshops'
      }
      this.$router.push({name:path});
    },
    bookingConfirm() {
      this.refreshSuggestion = true;
    }
  }
}
</script>

<style scoped>
.max-w-1280 {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
<template>
  <div class="cart">
    <div class="cart-header">
      Your Cart <span class="count">{{ cartCount }}</span>
    </div>
    <div class="" v-for="(order,oi) in getCartItems" :key="`o_${oi}`">
      <div v-for="(product,pi) in order.products" :key="pi" class="mt-5">
        <CartProductCard :key= "`pc_${pi}`" v-if="!product.isAddon" :product="product" :item="order" @removeCartItem="removeCartItem(oi,pi)" />
        <CartAddonCard
            v-else
            :product="product"
            :item="order"
        />
      </div>
    </div>
</div>
</template>

<script>
import CartProductCard from "@/components/Shop/Cart/CartProductCard.vue";
import CartAddonCard from "@/components/Shop/Cart/CartAddonCard.vue";

export default {
  name: "CartItems",
  components: {CartAddonCard, CartProductCard},
  computed: {
    cartCount() {
      return this.$store.getters.getCartCount;
    },
    getCartItems(){
      return this.$store.getters.getCartItems;
    },
  },
  data() {
    return {};
  },
  methods: {
    removeCartItem(oIndex,pIndex){
      this.$store.dispatch("removeCartItem",{oi: oIndex, pi: pIndex});
    }
  }
}
</script>
